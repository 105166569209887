<template>
  <div class="administrator-update-page page-layout">
    <PageHeader
      title="Modifier les informations d'un administrateur"
      :has-back="true"
      @back="goback"
    />

    <md-card class="meep-form">
      <!--mise a jour l'admin-->
      <md-card-content>
        <choose-item-modal
          v-show="isChooseOrganizationModalOpen"
          :itemsList="organizationsList"
          :chosenItem="chosenOrganization"
          :text="chooseItemModalText"
          :isDebug="isDebug"
          @close="closeChooseOrganizationModal"
          @saved="setOrganization"
        />

        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('organization_name') }">
            <label>Cabinet *</label>

            <md-input
              id="chooseOrganizationInput"
              v-model="chosenOrganization.name"
              v-validate="'required'"
              name="organization_name"
              onpaste="return false;"
              onkeydown="return false;"
              @focus="openChooseOrganizationModal"
              type="text"
            />

            <span class="md-error" v-show="errors.has('organization_name')">{{
              errors.first("organization_name")
            }}</span>
          </md-field>
        </div>

        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('firstname') }">
            <label>Prénom *</label>

            <md-input
              v-model="form.firstname"
              v-validate="'required'"
              name="firstname"
              type="text"
            />

            <span class="md-error" v-show="errors.has('firstname')">{{
              errors.first("firstname")
            }}</span>
          </md-field>
        </div>

        <!--mise a jour le nom-->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('lastname') }">
            <label>Nom *</label>

            <md-input
              v-model="form.lastname"
              v-validate="'required'"
              name="lastname"
              type="text"
            />

            <span class="md-error" v-show="errors.has('lasrname')">
              {{ errors.first("lastname") }}
            </span>
          </md-field>
        </div>

        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('email') }">
            <label>Email *</label>

            <md-input
              v-model="form.email"
              v-validate="'required'"
              name="email"
              type="email"
            />

            <span class="md-error" v-show="errors.has('email')">
              {{ errors.first("email") }}
            </span>
          </md-field>
        </div>

        <!--mise a jour le telefix-->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('telfixe') }">
            <label>Téléphone fixe</label>

            <md-input
              v-model="form.telfixe"
              v-validate="'telephone'"
              name="telfixe"
              type="tel"
            />

            <span class="md-error" v-show="errors.has('telfixe')">
              {{ errors.first("telfixe") }}
            </span>
          </md-field>
        </div>

        <!--mise a jour le teleportable-->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('telportable') }">
            <label>Téléphone portable</label>

            <md-input
              v-model="form.telportable"
              v-validate="'telephone'"
              name="telportable"
              type="tel"
            />

            <span class="md-error" v-show="errors.has('telportable')">
              {{ errors.first("telportable") }}
            </span>
          </md-field>
        </div>

        <!-- mise a jour l'adresse-->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('adresse') }">
            <label>Adresse</label>

            <md-input v-model="form.adresse" name="adresse" type="text" />

            <span class="md-error" v-show="errors.has('adresse')">
              {{ errors.first("adresse") }}
            </span>
          </md-field>
        </div>

        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
            <label>Code postal</label>

            <md-input
              v-model="form.codepostal"
              v-validate="'numeric|min:5|max:5'"
              name="codepostal"
              type="text"
            />

            <span class="md-error" v-show="errors.has('codepostal')">
              {{ errors.first("codepostal") }}
            </span>
          </md-field>
        </div>

        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('ville') }">
            <label>Ville</label>
            <md-input
              v-model="form.ville"
              v-validate="'alpha_spaces'"
              name="ville"
              type="ville"
            />
            <span class="md-error" v-show="errors.has('ville')">
              {{ errors.first("ville") }}
            </span>
          </md-field>
        </div>

        <div class="md-layout md-alignment-center-right">
          <md-button
            class="md-raised md-primary"
            @click.native="openConfirmDeleteModal"
          >
            Supprimer cet utilisateur
          </md-button>

          <md-button
            class="md-raised md-primary"
            @click.native="updateAdministrators"
          >
            Valider
          </md-button>
        </div>
      </md-card-content>
    </md-card>

    <confirm-action-modal
      v-if="isConfirmDeleteModalOpen"
      :text="confirmDeleteModalText"
      :objectToActUpon="form"
      @close="closeConfirmDeleteModal"
      @confirm="deleteAdministrators"
    />
  </div>
</template>

<script>
import users from "../../../model/users";
import chooseItemModal from "../../../components/modal/choose-item";
import confirmActionModal from "../../../components/modal/confirm-action";

/* Modèles */
import organizationsModel from "../../../model/organizations";
import PageHeader from "@/components/PageHeader";

export default {
  data() {
    return {
      isDebug: false,
      isChooseOrganizationModalOpen: false,
      isConfirmDeleteModalOpen: false,
      /* Tampon pour passer le cabinet en prop du modal */
      chosenOrganization: {
        id: null,
        name: "",
      },
      form: {
        scope: 1,
        id: "",
        organization_id: "",
        firstname: "",
        lastname: "",
        email: "",
        telefixe: "",
        teleportable: "",
        adresse: "",
        codepostal: "",
        ville: "",
      },
      chooseItemModalText: {
        header: "Choisir un cabinet :",
      },
      confirmDeleteModalText: {
        header: "Suppression d'un administrateur",
        body() {
          return "Vous êtes sur le point de supprimer l'administrateur";
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      },
      organizationsList: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    users
      .get(to.params.id)
      .then(formData => {
        next(vm => {
          vm.form = formData;
          if (vm.isDebug) {
            console.log("admin with id" + to.params.id + ":");
            console.log(vm.form);
          }
          vm.chosenOrganization.id = formData.organization_id;
          vm.chosenOrganization.name = formData.organization_name;
        });
      })
      .catch(() => {
        next(from.path);
      });
  },
  components: {
    PageHeader,
    "confirm-action-modal": confirmActionModal,
    "choose-item-modal": chooseItemModal,
  },

  methods: {
    openChooseOrganizationModal() {
      this.isChooseOrganizationModalOpen = true;
    },
    closeChooseOrganizationModal() {
      this.isChooseOrganizationModalOpen = false;
    },
    setOrganization(organization) {
      /* Assigner le tampon */
      this.chosenOrganization = organization;
      /* Assigner également l'id dans le formulaire */
      this.form.organization_id = organization.id;
    },
    goback() {
      window.history.back();
    },

    updateAdministrators() {
      this.$validator.validateAll().then(result => {
        if (result) {
          users
            .update(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: "L'administrateur a bien été modifié",
              });
              this.$router.push("/admin/administrators/");
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
    deleteAdministrators() {
      users
        .remove(this.form.id)
        .then(() => {
          this.$toasted.global.AppSucces({
            message: "L'administrator a suprimé",
          });
          this.$router.push("/admin/administrators/");
        })
        .catch(err => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },
    openConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalOpen = false;
    },
  },
  mounted() {
    /* Récupérer tout les cabinets */
    organizationsModel.getAll().then(organizations => {
      this.organizationsList = organizations;
    });
  },
};
</script>

<style lang="scss" scoped>
.administrator-update-page {
  .debug-log {
    max-height: 360px;
    overflow-y: scroll;
  }

  .md-card {
    margin: 32px auto;
  }
}
</style>
